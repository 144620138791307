import * as React from 'react';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import connectToRouter from 'js/lib/connectToRouter';

import withCCPA from 'bundles/marketing-consent/components/withCCPA';
import withGDPR from 'bundles/marketing-consent/components/withGDPR';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import { CourseraMetatags } from 'bundles/seo';

import 'css!bundles/accomplishments/styl/index';

type InputProps = {
  children: JSX.Element;
};

type Props = InputProps & {
  pathname: string;
};

export class AccomplishmentsApp extends React.Component<Props> {
  render() {
    const { children, pathname } = this.props;

    const isCertificatePage = pathname.includes('certificate');
    return (
      <div className="rc-AccomplishmentsApp">
        <CourseraMetatags metaNameAndProperties={{ disableCrawlerIndexing: true }} />
        {!isCertificatePage && <PageHeader showShoppingCart={true} showGDPRBanner />}
        <div style={{ minHeight: '60vh' }}>{children && React.cloneElement(children, this.props)}</div>
        {!isCertificatePage && <PageFooter />}
      </div>
    );
  }
}

export default compose<Props, InputProps>(
  connectToRouter(({ location: { pathname }, params: { code } }) => {
    return {
      code,
      pathname,
    };
  }),
  Retracked.createTrackedContainer(() => {
    return {
      namespace: {
        app: 'accomplishments_v2',
        page: 'accomplishments_pages',
      },
    };
  }),
  withGDPR,
  withCCPA
)(AccomplishmentsApp);
