import * as React from 'react';
import { Redirect, Route } from 'react-router';

import AccomplishmentsApp from 'bundles/accomplishments/components/AccomplishmentsApp';
import loadOnRoute from 'bundles/common/components/loadOnRoute';
import NotFound from 'bundles/phoenix/components/NotFound';

const CertificatePdfPage = loadOnRoute(() => import('bundles/accomplishments/components/pdf-page/CertificatePdfPage'));
const CourseAccomplishmentPage = loadOnRoute(
  () => import('bundles/accomplishments/components/course-certificates/CourseAccomplishmentPage')
);

const S12nAccomplishmentPage = loadOnRoute(
  () => import('bundles/accomplishments/components/s12n-certificates/S12nAccomplishmentPage')
);

const routes = (
  <Route path="/account/accomplishments" component={AccomplishmentsApp}>
    <Route path="verify/:code" getComponent={CourseAccomplishmentPage} />
    <Redirect from="records/:code" to="verify/:code" />
    <Route path="certificate/:code" getComponent={CertificatePdfPage} />
    <Route path="specialization/:code" getComponent={S12nAccomplishmentPage} />
    <Route path="specialization/certificate/:code" getComponent={CertificatePdfPage} />
    <Route path="professional-cert/:code" getComponent={S12nAccomplishmentPage} />
    <Route path="professional-cert/certificate/:code" getComponent={CertificatePdfPage} />
    <Route path="*" component={NotFound} />
  </Route>
);

export default routes;
